/* You can add global styles to this file, and also import other style files */
body
    background #111821

@media(min-width 991px)
    .mobile-only
        display none

    .desktop-only
        display block

@media(max-width 991px)
    .mobile-only
        display block

    .desktop-only
        display none

.swal2-popup
    background #1e2a3a !important

.swal2-content
    color: white !important

.swal2-styled.swal2-confirm
    background-color #EEA73B !important
    border-color #EEA73B !important